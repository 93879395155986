import React from 'react';
import { Login } from '../pages/Login';
import { Profile } from '../pages/Profile';
import { OrgChart } from '../pages/OrgChart';
import { OrgManagement } from '../pages/OrgManagement';
import { StructureManagement } from '../pages/StructureManagement';
import { EarningsPage } from '../pages/Earnings';
import { PaymentsPage } from '../pages/Payments';

export interface IRoute {
  path: string;
  component: React.ComponentType;
}

export enum RouteNames {
  LOGIN = '/login',
  PROFILE = '/profile',
  ORG_CHART = '/org-chart',
  ORG_MANAGEMENT = '/org-management',
  STRUCTURE_MANAGEMENT = '/structure-management',
  EARNINGS = '/earnings',
  PAYMENTS = '/payments'
}

export const publicRoutes: IRoute[] = [
  {
    path: RouteNames.LOGIN,
    component: Login
  }
];

export const privateRoutes: IRoute[] = [
  { path: RouteNames.PROFILE, component: Profile },
  { path: RouteNames.ORG_CHART, component: OrgChart },
  { path: RouteNames.EARNINGS, component: EarningsPage },
  { path: RouteNames.PAYMENTS, component: PaymentsPage }
];

export const managementRoutes: IRoute[] = [
  { path: RouteNames.ORG_MANAGEMENT, component: OrgManagement },
  { path: RouteNames.STRUCTURE_MANAGEMENT, component: StructureManagement }
];
