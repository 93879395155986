import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { AuthResponse } from '../types/AuthResponse';

const searchParams = new URLSearchParams(document.location.search);
const accessToken = searchParams.get('accessToken');

if (accessToken) {
  localStorage.setItem('token', accessToken);
}

let url = 'https://dev.tech.anroit.com';

//if (process.env.NODE_ENV === 'production') {
//  url = `https://api.${window.location.host}`;
//}

const $api = axios.create({
  withCredentials: true,
  baseURL: url
});

$api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers = config.headers ?? {};
    config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
    config.headers['Access-Control-Allow-Origin'] = '*';
    return config;
  },
  error => error
);

$api.interceptors.response.use(
  (config: AxiosResponse) => {
    return config;
  },
  async error => {
    const originalRequest = error.config;

    if (error.response?.status == 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;

      try {
        const response = await axios.get<AuthResponse>(`${url}/api/refresh`, {
          withCredentials: true
        });

        localStorage.setItem('token', response.data.accessToken);
        return $api.request(originalRequest);
      } catch (e) {
        console.log('Не авторизован');
      }
    }

    throw error;
  }
);

export default $api;
export const API_URL = url;
