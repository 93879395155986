import React from 'react';
import PageFrame from '../../components/PageFrame';
import {Button, Grid, InputAdornment, InputLabel, OutlinedInput, Paper, Typography} from '@mui/material';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';
import {PaymentsTable} from "./PaymentsTable";

interface PaymentsPageProps {

}

export const PaymentsPage = (props: PaymentsPageProps) => {
  const paymentValue = 1500;

  function handleRequestPayment() {}

  return (
    <PageFrame>
      <h1>Payments</h1>

      <Paper>
        <Box p={{ xs: 2, md: 4 }}>
          <Grid container spacing={2} alignItems='center'>
            <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
              <FormControl fullWidth>
                <InputLabel htmlFor='outlined-adornment-amount'>Amount to be paid</InputLabel>
                <OutlinedInput
                  id='outlined-adornment-amount'
                  value={paymentValue}
                  readOnly
                  startAdornment={<InputAdornment position='start'>€</InputAdornment>}
                  label='Amount to be paid'
                />
              </FormControl>
            </Grid>
            <Grid item xs='auto'>
              <Button
                variant='contained'
                size='large'
                disabled={paymentValue < 100}
                onClick={handleRequestPayment}>
                Request payment
              </Button>
            </Grid>
          </Grid>

          <Box mt={5}>
            <Typography variant='h6' gutterBottom>All payments</Typography>
            <PaymentsTable data={[]} loading={true} error={false}/>
          </Box>
        </Box>
      </Paper>
    </PageFrame>
  );
};
