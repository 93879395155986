import React, { useEffect, useState } from 'react';
import PageFrame from '../../components/PageFrame';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { TransitionGroup } from 'react-transition-group';
import { Paper } from '@mui/material';
import { InputButton } from '../../components/UI/InputButton';
import { Actions } from '../../store/reducers/ManagementSlice';
import { Item } from './Item';
import styled from 'styled-components';
import { getUsers, updateStructure } from '../../store/reducers/ActionCreators';
import { Structure } from '../../types/Management';

// TODO при удалении департамента или команды нужно сбрасывать удаленные варианты у пользователей
export const StructureManagement = () => {
  const dispatch = useAppDispatch();
  const { structure: baseStructure } = useAppSelector(state => state.managementReducer);
  const [activeDepartment, setActiveDepartment] = useState<string | null>(null);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [structure, setStructure] = useState<Structure | null>(null);

  useEffect(() => {
    if (baseStructure) {
      setStructure({ ...baseStructure });
    }
  }, [baseStructure]);

  useEffect(() => {
    setIsChanged(JSON.stringify(structure) !== JSON.stringify(baseStructure));
  }, [structure]);

  function addNewDepartment(value: string) {
    if (structure && structure[value]) {
      return;
    }
    setStructure({ ...structure, [value]: [] });
  }

  function removeDepartment(value: string) {
    if (structure) {
      setStructure(
        Object.fromEntries(Object.entries(structure).filter(([key, val]) => key !== value))
      );
      setActiveDepartment(null);
    }
  }

  function addNewTeam(value: string) {
    if (activeDepartment && structure) {
      setStructure({
        ...structure,
        [activeDepartment]: [...structure[activeDepartment], value]
      });
    }
  }
  function removeTeam(value: string) {
    if (activeDepartment && structure) {
      setStructure({
        ...structure,
        [activeDepartment]: [...structure[activeDepartment].filter(item => item !== value)]
      });
    }
  }

  function reset() {
    setActiveDepartment(null);
    setStructure(baseStructure || null);
  }

  function save() {
    if (structure) {
      const response = dispatch(updateStructure(structure));
      response.then(response => {
        if (response.status === 200) {
          dispatch(getUsers());
        }
      });
    }
  }

  return (
    <PageFrame>
      <h1>Structure Management</h1>
      <StyledContainer maxWidth='md'>
        <Stack spacing={2}>
          <Paper sx={{ p: 2 }}>
            {structure !== null && (
              <FormControl fullWidth>
                <FormLabel id='department-group-label'>Departments</FormLabel>
                <RadioGroup
                  aria-labelledby='department-group-label'
                  onChange={(event: React.SyntheticEvent, value: string) =>
                    setActiveDepartment(value)
                  }
                  name='department-buttons-group'>
                  <TransitionGroup>
                    {Object.keys(structure).map(department => (
                      <Collapse key={department}>
                        <Item name={department} removeItem={removeDepartment}>
                          <FormControlLabel
                            value={department}
                            control={
                              <Radio checked={department === activeDepartment ? true : false} />
                            }
                            label={department}
                          />
                        </Item>
                      </Collapse>
                    ))}
                  </TransitionGroup>
                </RadioGroup>
              </FormControl>
            )}
            <InputButton
              onEnter={(value: string) => {
                addNewDepartment(value);
                return '';
              }}
              textButton='Add'
            />
          </Paper>
          {structure && activeDepartment && (
            <Paper sx={{ p: 2 }}>
              <FormControl fullWidth>
                {structure[activeDepartment].length > 0 ? (
                  <>
                    <FormLabel id='team-group-label'>{activeDepartment} team's</FormLabel>
                    <List>
                      <TransitionGroup>
                        {structure[activeDepartment].map(team => (
                          <Collapse key={team}>
                            <Item name={team} removeItem={removeTeam}>
                              <ListItemText primary={team} />
                            </Item>
                          </Collapse>
                        ))}
                      </TransitionGroup>
                    </List>
                  </>
                ) : (
                  <Box sx={{ p: 2 }}>Empty...</Box>
                )}
              </FormControl>
              <InputButton
                onEnter={(value: string) => {
                  addNewTeam(value);
                  return '';
                }}
                textButton='Add'
              />
            </Paper>
          )}
          <Box>
            <Stack spacing={2} direction='row'>
              <Button disabled={isChanged ? false : true} variant='outlined' onClick={reset}>
                Reset
              </Button>
              <Button disabled={isChanged ? false : true} variant='contained' onClick={save}>
                Save changes
              </Button>
            </Stack>
          </Box>
        </Stack>
      </StyledContainer>
    </PageFrame>
  );
};

const StyledContainer = styled(Container)`
  &.MuiContainer-root {
    padding: 0;
  }
`;
