import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { User } from '../types/User';
import StarIcon from '@mui/icons-material/Star';

interface Props extends User {
  className: string;
}

export const ChartItem: FC<Props> = props => {
  const {
    name,
    surname,
    department,
    team,
    team_lead,
    department_lead,
    position,
    picture,
    className
  } = props;

  return (
    <StyledNode className={className}>
      <StyledLead>
        {className.search('department-lead') !== -1 && (
          <StarIcon sx={{ color: '#0277bd' }} titleAccess='Department lead' />
        )}
        {className.search('team-lead') !== -1 && (
          <StarIcon sx={{ color: '#2e7d32' }} titleAccess='Team lead' />
        )}
      </StyledLead>
      <StyledImg>
        <img src={picture} alt='profile photo' />
      </StyledImg>
      <StyledInfo>
        <div>{`Name: ${name} ${surname}`}</div>
        {department && <div>{`Department: ${department}`}</div>}
        {team && <div>{`Team: ${team}`}</div>}
        {department_lead && (
          <div>
            <b>{`Department lead`}</b>
          </div>
        )}
        {team_lead && (
          <div>
            <b>{`Team lead`}</b>
          </div>
        )}
        <div>{`Position: ${position}`}</div>
      </StyledInfo>
    </StyledNode>
  );
};

const StyledNode = styled.div`
  position: relative;
  padding: 10px 5px;
  font-size: 14px;
  border-radius: 8px;
  display: inline-block;
  border: 2px solid #1976d2;
  background: #fafafa;
  display: inline-flex;
  flex-direction: column;
  width: max-content;
  min-width: 130px;
  align-items: flex-start;
  &.top {
  }
`;

const StyledImg = styled.div`
  & img {
    height: 50px;
    border-radius: 50%;
    border: 1px solid #0d47a1;
  }
`;

const StyledLead = styled.div`
  position: absolute;
  top: 2px;
  right: 2px;
`;

const StyledInfo = styled.div`
  text-align: left;
`;
