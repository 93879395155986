import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import PageFrame from '../../components/PageFrame';
import { Button, Collapse, LinearProgress, Paper, styled } from '@mui/material';
import Box from '@mui/material/Box';
import { AddEarningForm } from './AddEarningForm';
import {
  EarningStatus,
  Employee,
  NewEarningFormData,
  PaymentsListItem
} from '../../types/earnings';
import { GridCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import dayjs from 'dayjs';
import { EarningStatusList } from '../../utils/earningStatus';

const paymentsList = [
  {
    id: 1,
    paid_at: '2017-11-01 15:50:22',
    status: 1,
    employee: {
      id: 1,
      name: 'Vasya',
      surname: 'Pupkin',
      status: 1
    },
    amount: 1800
  },
  {
    id: 2,
    paid_at: '2017-11-01 15:50:22',
    status: 1,
    employee: {
      id: 1,
      name: 'Ivan',
      surname: 'Ivanovish',
      status: 1
    },
    amount: 2500
  },
  {
    id: 3,
    paid_at: '2017-11-01 15:50:22',
    status: 1,
    employee: {
      id: 1,
      name: 'John',
      surname: 'Doe',
      status: 1
    },
    amount: 2150
  },
  {
    id: 4,
    paid_at: '2017-11-01 15:50:22',
    status: 1,
    employee: {
      id: 1,
      name: 'Vladimir',
      surname: 'Pupkin',
      status: 1
    },
    amount: 3600
  }
];

interface EarningsPageProps {}

const StatusButton = styled(Button)`
  &&& {
    &.Mui-disabled {
      color: green;
    }
  }
`;

export const EarningsPage = (props: EarningsPageProps) => {
  const [expanded, setExpanded] = React.useState(false);

  const [data, setData] = useState<PaymentsListItem[]>(paymentsList);
  const [loading, setLoading] = useState<boolean>(false);
  const [earningFormData, setEarningFormData] = useState<NewEarningFormData>({
    employee: null,
    amount: 0,
    date: dayjs(),
    status: EarningStatus.Draft
  });

  const columns = [
    { field: 'paid_at', headerName: 'Paid At', minWidth: 140, flex: 1 },
    {
      field: 'employee',
      headerName: 'Employee',
      minWidth: 150,
      flex: 1,
      valueGetter: (params: GridCellParams<Employee>) => {
        const employee = params.value;
        if (!employee) return '';
        return `${employee.name || ''} ${employee.surname || ''}`;
      }
    },
    {
      field: 'amount',
      headerName: 'Amount',
      type: 'number',
      valueGetter: (params: GridCellParams<number>) => {
        const amount = params.value;
        return Number(amount);
      }
    },
    {
      field: 'status',
      headerName: 'Status',
      renderCell: (params: GridCellParams<EarningStatus>) => {
        const status = params.value;
        const onClick = (e: React.MouseEvent<HTMLButtonElement>) => {
          e.stopPropagation();

          // change status
        };

        return (
          <StatusButton onClick={onClick} disabled={status === EarningStatus.Paid}>
            {EarningStatusList.find(item => item.value === status)?.name}
          </StatusButton>
        );
      }
    }
  ];

  function handleAddEarning() {
    setData([
      ...data,
      {
        id: data.length + 1,
        paid_at: earningFormData.date.toString(),
        status: earningFormData.status,
        employee: {
          id: 1,
          name: 'Ivan',
          surname: 'Ivanovish',
          status: 1
        },
        amount: earningFormData.amount
      }
    ]);
    setExpanded(false);
  }

  function handleOpenForm() {
    setExpanded(prevState => !prevState);
    setEarningFormData({
      ...earningFormData,
      employee: null,
      amount: 0,
      date: dayjs()
    });
  }

  function setField(name: keyof NewEarningFormData, value: any) {
    setEarningFormData({
      ...earningFormData,
      [name]: value
    });
  }

  return (
    <PageFrame>
      <h1>Earnings</h1>

      <Paper>
        <Box p={{ xs: 2, md: 4 }}>
          <Box mb={2}>
            <Button onClick={handleOpenForm} variant='contained'>
              Add earning
            </Button>
          </Box>

          <Collapse in={expanded} timeout='auto' unmountOnExit>
            <Box mb={2}>
              <AddEarningForm
                onSubmit={handleAddEarning}
                setField={(name, value) => setField(name, value)}
                formData={earningFormData}
              />
            </Box>
          </Collapse>

          <div style={{ height: 600, width: '100%' }}>
            <DataGrid
              components={{
                LoadingOverlay: LinearProgress
              }}
              loading={loading}
              rows={data}
              columns={columns}
              pageSize={10}
              rowsPerPageOptions={[10]}
            />
          </div>
        </Box>
      </Paper>
    </PageFrame>
  );
};
