import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Countries, Roles, Statuses, Structure } from '../../types/Management';

interface State {
  countries: Countries | null;
  roles: Roles | null;
  statuses: Statuses | null;
  structure: Structure | null;
}

const initialState: State = {
  countries: null,
  roles: ['User', 'Admin', 'Manager'],
  statuses: ['Active', 'Vacation', 'Inactive'],
  structure: {
    Engineering: ['Front', 'Back'],
    'Sales and Marketing': ['Sellers'],
    Testing: ['Mobile', 'Desktop', 'QA engineers']
  }
};

const slice = createSlice({
  name: 'management',
  initialState,
  reducers: {
    setCountries(state, action: PayloadAction<Countries>) {
      state.countries = action.payload;
    },
    setRoles(state, action: PayloadAction<Roles>) {
      state.roles = action.payload;
    },
    setStatuses(state, action: PayloadAction<Statuses>) {
      state.statuses = action.payload;
    },
    setStructure(state, action: PayloadAction<Structure>) {
      state.structure = action.payload;
    }
  }
});

export default slice.reducer;
export const Actions = slice.actions;
