import React, { useEffect } from 'react';
import { Button, FormControl, Grid, InputLabel, MenuItem } from '@mui/material';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { NewEarningFormData } from '../../types/earnings';
import {EarningStatusList} from "../../utils/earningStatus";

interface AddEarningFormProps {
  onSubmit: () => void;
  setField: (name: keyof NewEarningFormData, value: any) => void;
  formData: NewEarningFormData;
}

export const AddEarningForm = (props: AddEarningFormProps) => {
  const { onSubmit, formData, setField } = props;

  useEffect(() => {
    const pressEnterHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener('keydown', pressEnterHandler);

    return () => document.removeEventListener('keydown', pressEnterHandler);
  }, []);



  return (
    <Grid container spacing={2} component='form'>
      <Grid item xs={12} md={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
              label="Date"
              value={formData.date}
              onChange={(newValue: any) => {
                setField('date', newValue);
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl fullWidth>
          <InputLabel id='status-select-label'>Status</InputLabel>
          <Select
            value={formData.status}
            onChange={e => setField('status', e.target.value)}
            labelId='status-select-label'
            label='Timezone'>
            {EarningStatusList.map(({ value, name }) => (
              <MenuItem key={value} value={value}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          onChange={e => setField('employee', e.target.value)}
          value={formData.employee}
          label='Employee'
          variant='outlined'
          fullWidth
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          onChange={e => setField('amount', e.target.value)}
          value={formData.amount}
          label='Amount'
          type='number'
          variant='outlined'
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <Button onClick={onSubmit} variant='outlined'>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};
