import { EarningStatus } from '../types/earnings';

export const EarningStatusList = [
  {
    value: EarningStatus.Draft,
    name: 'Draft'
  },
  {
    value: EarningStatus.ToBePaid,
    name: 'To be paid'
  },
  {
    value: EarningStatus.Paid,
    name: 'Paid'
  }
] as { value: EarningStatus; name: string }[];
