import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Box, CircularProgress } from '@mui/material';
import { Navigate, Routes, Route } from 'react-router-dom';
import { managementRoutes, privateRoutes, publicRoutes, RouteNames } from '../router';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import { fetchUser } from '../store/reducers/ActionCreators';

export const AppRouter = () => {
  const dispatch = useAppDispatch();
  const { user, isLoading } = useAppSelector(state => state.authReducer);
  const syncRef = useRef(false);

  useEffect(() => {
    if (syncRef.current === true) {
      return;
    }

    syncRef.current = true;
    dispatch(fetchUser());
  }, []);

  if (isLoading) {
    return (
      <StyledBox>
        <CircularProgress />
      </StyledBox>
    );
  }

  if (user === null) {
    return (
      <Routes>
        {publicRoutes.map(route => (
          <Route key={route.path} element={<route.component />} path={route.path} />
        ))}
        <Route path='*' element={<Navigate to={RouteNames.LOGIN} />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {privateRoutes.map(route => (
        <Route key={route.path} element={<route.component />} path={route.path} />
      ))}
      {(user.role === 'Admin' || user.role === 'Manager') &&
        managementRoutes.map(route => (
          <Route key={route.path} element={<route.component />} path={route.path} />
        ))}
      <Route path='*' element={<Navigate to={RouteNames.PROFILE} />} />
    </Routes>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;
