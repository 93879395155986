import React from 'react';
import { Button, Box } from '@mui/material';
import Service from '../API/service';
import styled from 'styled-components';

export const Login = () => {
  return (
    <StyledBox>
      <StyledButton onClick={() => Service.loginWithGoogle()} variant='outlined'>
        Login with Google
      </StyledButton>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledButton = styled(Button)``;
