import React, { useState, useEffect } from 'react';
import PageFrame from '../components/PageFrame';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { User } from '../types/User';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { getUsers, updateProfile } from '../store/reducers/ActionCreators';
import styled from 'styled-components';
import { Typography } from '@mui/material';

export const OrgManagement = () => {
  const dispatch = useAppDispatch();
  const { userList } = useAppSelector(state => state.usersReducer);
  const { roles, statuses, structure } = useAppSelector(state => state.managementReducer);
  const { user: authUser } = useAppSelector(state => state.authReducer);
  const [user, setUser] = useState<User | null>(null);
  const [isChanged, setIsChanged] = useState<boolean>(false);

  useEffect(() => {
    if (user) {
      setIsChanged(
        JSON.stringify(user) !== JSON.stringify(userList.find(item => item.id === user.id))
      );
    }
  }, [user]);

  function reset() {
    setUser(userList.find(item => item.id === user?.id) || null);
  }

  function save() {
    if (user) {
      const response = dispatch(updateProfile(user));
      response.then(response => {
        if (response.status === 200) {
          dispatch(getUsers());
        }
      });
    }
  }

  return (
    <PageFrame>
      <h1>Org Management</h1>

      <StyledContainer maxWidth='md'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              getOptionLabel={(option: User) => `${option.name} ${option.surname || ''}`.trim()}
              noOptionsText='No users'
              options={userList}
              renderInput={params => <TextField {...params} label='Users' />}
              onChange={(event: React.SyntheticEvent, value: User | null) => setUser(value)}
            />
          </Grid>

          {user && (
            <>
              {authUser?.role === 'Admin' && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id='role-select-label'>Role</InputLabel>
                    <Select
                      labelId='role-select-label'
                      value={user.role}
                      label='Role'
                      onChange={(event: SelectChangeEvent<unknown>) =>
                        setUser({ ...user, role: event.target.value as string })
                      }>
                      {roles &&
                        roles.map(role => (
                          <MenuItem key={role} value={role}>
                            {role}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <TextField
                  autoComplete='off'
                  label='Position'
                  value={user.position || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setUser({ ...user, position: event.target.value as string })
                  }
                  variant='outlined'
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id='department-select-label'>Department</InputLabel>
                  <Select
                    labelId='department-select-label'
                    value={user.department || ''}
                    label='Department'
                    onChange={(event: SelectChangeEvent<unknown>) =>
                      setUser({ ...user, department: event.target.value as string, team: null })
                    }>
                    {structure &&
                      Object.keys(structure).map(department => (
                        <MenuItem key={department} value={department}>
                          {department}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Switch checked={user.department_lead} name='Department lead' />}
                  onChange={(e, checked: boolean) =>
                    setUser({ ...user, department_lead: checked as boolean })
                  }
                  disabled={user.department ? false : true}
                  label='Department lead'
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id='team-select-label'>Team</InputLabel>
                  <Select
                    disabled={user.department ? false : true}
                    labelId='team-select-label'
                    value={user.team || ''}
                    label='Team'
                    onChange={(event: SelectChangeEvent<unknown>) =>
                      setUser({ ...user, team: event.target.value as string })
                    }>
                    {structure &&
                      user.department &&
                      structure?.[user.department] &&
                      structure?.[user.department].map(team => (
                        <MenuItem key={team} value={team}>
                          {team}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControlLabel
                  control={<Switch checked={user.team_lead} name='Team lead' />}
                  onChange={(e, checked: boolean) =>
                    setUser({ ...user, team_lead: checked as boolean })
                  }
                  disabled={user.team ? false : true}
                  label='Team lead'
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id='status-select-label'>Status</InputLabel>
                  <Select
                    labelId='status-select-label'
                    value={user.status}
                    label='Status'
                    onChange={(event: SelectChangeEvent<unknown>) =>
                      setUser({ ...user, status: event.target.value as string })
                    }>
                    {statuses &&
                      statuses.map(status => (
                        <MenuItem key={status} value={status}>
                          {status}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label='Alias'
                  autoComplete='off'
                  value={user.alias || ''}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setUser({ ...user, alias: event.target.value as string })
                  }
                  variant='outlined'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Autocomplete
                  getOptionLabel={(user: User) =>
                    `${user.name} ${user.surname || ''} (id: ${user.id})`.trim()
                  }
                  noOptionsText='No users'
                  options={userList}
                  renderInput={params => <TextField {...params} label='HEAD ID' />}
                  onChange={(event: React.SyntheticEvent, value: User | null) =>
                    setUser({ ...user, head_id: value?.id || null })
                  }
                />
                <Typography variant='subtitle2' sx={{ fontSize: '13px', p: '9px', lineHeight: 1 }}>
                  HEAD ID требуется устанавливать для отображения пользователя в org chart.
                  <br /> Если в HEAD ID задать самого пользователя, то с него будет начинаться org
                  chart (CEO настройка) <br />
                  Если в HEAD ID сбросить значение (null), то пользователь не будет отображаться в
                  org chart <br />
                  По правилам настройки HEAD ID в него требуется поставить пользователя следуя
                  иерархии: <br />
                  <b>team_lead -&gt; department_lead -&gt; CEO (или user)</b>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={2} direction='row'>
                  <Button disabled={isChanged ? false : true} variant='outlined' onClick={reset}>
                    Reset
                  </Button>
                  <Button disabled={isChanged ? false : true} variant='contained' onClick={save}>
                    Save changes
                  </Button>
                </Stack>
              </Grid>
            </>
          )}
        </Grid>
      </StyledContainer>
    </PageFrame>
  );
};

const StyledContainer = styled(Container)`
  &.MuiContainer-root {
    padding: 0;
  }
`;
