import React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { LinearProgress, Typography } from '@mui/material';
import { PaymentsState } from '../../types/Payment';

type PaymentsTableProps = Pick<PaymentsState, 'data' | 'error' | 'loading'>;

export const PaymentsTable = (props: PaymentsTableProps) => {
  const { data, loading, error } = props;

  const columns: GridColDef[] = [
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 150,
      flex: 1
    },
    {
      field: 'sum',
      headerName: 'Sum',
      minWidth: 150,
      flex: 1
    }
  ];

  return (
    <div style={{ height: 400, width: '100%' }}>
      {error && <Typography color='red'>Something went wrong</Typography>}
      <DataGrid
        components={{
          LoadingOverlay: LinearProgress
        }}
        loading={loading}
        rows={data || []}
        columns={columns}
        pageSize={10}
        rowsPerPageOptions={[10]}
      />
    </div>
  );
};
