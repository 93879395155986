import React, { FC, useEffect, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
  name: string;
  children: React.ReactNode;
  removeItem: (value: string) => void;
}

export const Item: FC<Props> = props => {
  const { name, children, removeItem } = props;
  return (
    <ListItem
      secondaryAction={
        <IconButton edge='end' aria-label='delete' title='Delete' onClick={() => removeItem(name)}>
          <DeleteIcon />
        </IconButton>
      }>
      {children}
    </ListItem>
  );
};
