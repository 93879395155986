import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserData } from '../../types/User';

interface State {
  user: UserData | null;
  error: string;
  isLoading: boolean;
}

const initialState: State = {
  user: null,
  error: '',
  isLoading: true
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    userFetching(state) {
      state.isLoading = true;
    },
    userFetchingSuccess(state, action: PayloadAction<UserData>) {
      state.isLoading = false;
      state.error = '';
      state.user = action.payload;
    },
    userFetchingError(state, action: PayloadAction<string>) {
      state.isLoading = false;
      state.error = action.payload;
      console.error('Error fetching user');
    },
    reset(state) {
      state.isLoading = false;
      state.error = '';
      state.user = null;
    }
  }
});

export default slice.reducer;
export const Actions = slice.actions;
