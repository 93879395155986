import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Button, CircularProgress, Modal, Typography } from '@mui/material';

interface ImageUploadProps {}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24
};

export const ImageUpload = (props: ImageUploadProps) => {
  const [image, setImage] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const onUpload = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    if (!target?.files) return;

    const fileReader = new FileReader();

    fileReader.readAsDataURL(target?.files[0]);
    fileReader.onprogress = () => {
      setLoading(true);
    };
    fileReader.onload = e => {
      e?.target?.result && setImage(e?.target?.result.toString());
      setLoading(false);
    };
    fileReader.onerror = err => {
      console.log(err);
      setError(true);
      setLoading(false);
      setImage('');
    };
  };

  const handleModalOpen = () => {
    if (!image) return;

    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column'
      }}>
      <Box
        onClick={handleModalOpen}
        sx={{
          width: 150,
          height: 150,
          background: `url(${image})`,
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          bgcolor: '#eee',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center'
        }}>
        {error && <Typography color='red'>Image uploading error</Typography>}
      </Box>

      <input
        accept='image/*'
        style={{ display: 'none' }}
        id='raised-button-file'
        multiple
        onChange={onUpload}
        type='file'
      />
      <label htmlFor='raised-button-file'>
        <Button size='small' component='span'>
          {loading ? <CircularProgress /> : 'Upload new photo'}
        </Button>
      </label>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box sx={style}>
          <img
            style={{ display: 'block', maxWidth: '100%', maxHeight: '80vh' }}
            src={image}
            alt=''
          />
        </Box>
      </Modal>
    </Box>
  );
};
