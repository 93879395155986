import Service from '../../API/service';
import { AppDispatch } from '../store';
import { Actions as AuthActions } from './AuthSlice';
import { Actions as UsersActions } from './UsersSlice';
import { Actions as ManagementActions } from './ManagementSlice';
import { UserData } from '../../types/User';
import { Structure } from '../../types/Management';

export const fetchUser = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(AuthActions.userFetching());
    const response = await Service.checkUser();
    localStorage.setItem('token', response.data.accessToken);
    //dispatch(AuthActions.userFetchingSuccess({ ...response.data.user, role: 'Admin' }));
    dispatch(AuthActions.userFetchingSuccess(response.data.user));
    const users = await Service.getUsers();
    dispatch(UsersActions.fetchingSuccess(users.data));
    const countries = await Service.getCountries();
    dispatch(ManagementActions.setCountries(countries));
    const roles = await Service.getRoles();
    dispatch(ManagementActions.setRoles(roles));
    const statuses = await Service.getStatuses();
    dispatch(ManagementActions.setStatuses(statuses));
  } catch (e: any) {
    dispatch(AuthActions.userFetchingError(e.response?.data?.message || 'Error fetching user'));
  }
};

export const logoutUser = () => async (dispatch: AppDispatch) => {
  try {
    const response = await Service.logout();
    if (response?.data?.message === 'Successfully logged out') {
      localStorage.removeItem('token');
      dispatch(AuthActions.reset());
    }
  } catch (e: any) {
    dispatch(AuthActions.userFetchingError(e.response?.data?.message || 'Error fetching user'));
  }
};

export const getUsers = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(UsersActions.fetching());
    const response = await Service.getUsers();
    dispatch(UsersActions.fetchingSuccess(response.data));
  } catch (e: any) {
    dispatch(UsersActions.fetchingError(e.response?.data?.message || 'Error fetching'));
  }
};

export const getProfile = (id: number) => async (dispatch: AppDispatch) => {
  try {
    dispatch(UsersActions.fetching());
    const response = await Service.getProfile(id);
    dispatch(UsersActions.fetchingUser(response.data));
  } catch (e: any) {
    dispatch(UsersActions.fetchingError(e.response?.data?.message || 'Error fetching user'));
  }
};

export const deleteProfile = (id: number) => async (dispatch: AppDispatch) => {
  return await Service.deleteProfile(id);
};

export const updateProfile = (data: Partial<UserData>) => async () => {
  return await Service.updateProfile(data);
};

export const updateStructure = (data: Structure) => async () => {
  return await Service.setStructure(data);
};
