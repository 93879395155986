import React, { useEffect } from 'react';
import './App.css';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme';
import { AppRouter } from './components/AppRouter';
import { BrowserRouter } from 'react-router-dom';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
