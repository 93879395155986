import React, { FC } from 'react';
import styled from 'styled-components';
import { useNavigate, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import logo from '../assets/images/logo.svg';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Groups2Icon from '@mui/icons-material/Groups2';
import PaidRoundedIcon from '@mui/icons-material/PaidRounded';
import PaymentsRoundedIcon from '@mui/icons-material/PaymentsRounded';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import { RouteNames } from '../router';
import { useAppSelector } from '../hooks/redux';

export const LeftBar: FC<{}> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAppSelector(state => state.authReducer);
  const leftBar = [
    {
      text: 'Profile',
      className: location.pathname === RouteNames.PROFILE && 'active',
      icon: <AccountCircle />,
      onClick: () => {
        navigate(RouteNames.PROFILE);
      }
    },
    {
      text: 'Org Chart',
      className: location.pathname === RouteNames.ORG_CHART && 'active',
      icon: <Groups2Icon />,
      onClick: () => {
        navigate(RouteNames.ORG_CHART);
      }
    },
    {
      text: 'Earnings',
      className: location.pathname === RouteNames.EARNINGS && 'active',
      icon: <PaidRoundedIcon />,
      onClick: () => {
        navigate(RouteNames.EARNINGS);
      }
    },
    {
      text: 'Payments',
      className: location.pathname === RouteNames.PAYMENTS && 'active',
      icon: <PaymentsRoundedIcon />,
      onClick: () => {
        navigate(RouteNames.PAYMENTS);
      }
    }
  ];

  if (user?.role === 'Admin' || user?.role === 'Manager') {
    leftBar.splice(
      2,
      0,
      {
        text: 'Structure Management',
        className: location.pathname === RouteNames.STRUCTURE_MANAGEMENT && 'active',
        icon: <AccountTreeIcon />,
        onClick: () => {
          navigate(RouteNames.STRUCTURE_MANAGEMENT);
        }
      },
      {
        text: 'Org Management',
        className: location.pathname === RouteNames.ORG_MANAGEMENT && 'active',
        icon: <ManageAccountsIcon />,
        onClick: () => {
          navigate(RouteNames.ORG_MANAGEMENT);
        }
      }
    );
  }

  return (
    <>
      <StyledToolbar>
        <img src={logo} alt='logo' />
      </StyledToolbar>
      <List>
        {leftBar.map((props, index) => (
          <StyledListItem key={props.text}>
            <ListItemButton onClick={props.onClick} className={props.className || ''}>
              <ListItemIcon>{props.icon}</ListItemIcon>
              <ListItemText primary={props.text} />
            </ListItemButton>
          </StyledListItem>
        ))}
      </List>
    </>
  );
};

const StyledListItem = styled(ListItem)`
  &.MuiListItem-root {
    font-style: normal;
    font-weight: 500;
    padding: 0;
  }

  & .MuiButtonBase-root {
    &.active {
      background-color: #eee;

      & .MuiListItemIcon-root,
      & .MuiTypography-root {
        color: #444;
      }
    }
  }

  & .MuiTypography-root {
    font-size: 14px;
    line-height: 18px;
  }

  & .MuiListItemIcon-root {
    min-width: 28px;
    margin-left: 2px;
  }

  & .MuiSvgIcon-root {
    width: 18px;
  }
`;

const StyledToolbar = styled(Toolbar)`
  &.MuiToolbar-root {
    min-height: auto;
    height: 104px;
    margin: 0 auto;
    padding: 0;
  }

  & img {
    margin-left: 0;
    margin-top: 0;
  }
`;
