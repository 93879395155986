import React, { FC } from 'react';
import styled from 'styled-components';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Avatar from '@mui/material/Avatar';
import { logoutUser } from '../store/reducers/ActionCreators';

interface TopBarProps {
  drawerWidth: number;
  handleDrawerToggle: () => void;
}

export const TopBar: FC<TopBarProps> = ({ drawerWidth, handleDrawerToggle }) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);
  const { user } = useAppSelector(state => state.authReducer);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const dispatch = useAppDispatch();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenu = (
    <>
      <StyledMenu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}>
        <MenuItem onClick={() => dispatch(logoutUser())}>Logout</MenuItem>
      </StyledMenu>
    </>
  );

  return (
    <>
      <StyledAppBar
        position='fixed'
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` }
        }}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}>
            <MenuIcon />
          </IconButton>

          <StyledContent>
            <LeftBox>Hello, {user?.name}</LeftBox>

            <RightBox>
              <Box sx={{ display: 'flex' }}>
                <IconButton
                  size='large'
                  edge='end'
                  aria-label='account of current user'
                  aria-haspopup='true'
                  onClick={handleProfileMenuOpen}
                  color='inherit'>
                  {user?.picture ? (
                    <Avatar alt='user avatar' src={user?.picture} />
                  ) : (
                    <AccountCircle />
                  )}
                </IconButton>
              </Box>
            </RightBox>
          </StyledContent>
        </Toolbar>
      </StyledAppBar>
      {renderMenu}
    </>
  );
};

const StyledAppBar = styled(AppBar)`
  &.MuiPaper-root {
    background-color: #fff;
    color: #64748b;
    border-bottom: 1px solid #e6e6e6;
    box-shadow: none;
  }
  & .MuiToolbar-root {
    min-height: 60px;
  }
`;

const StyledMenu = styled(Menu)`
  & .MuiPaper-root {
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid #eaedfc;
  }
  & .MuiList-root {
    width: 226px;
    // height: 160px;
    padding: 10px;
  }
  & .MuiMenuItem-root {
    font-weight: 400;
    font-size: 12px;
    height: 38px;
    min-height: 38px;
  }
`;

const StyledContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const LeftBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (max-width: 900px) {
    flex-direction: column;
  }
`;
const RightBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
