import React, { FC, useEffect, useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

interface Props {
  value?: string;
  textButton?: string;
  onChange?: (value: string) => void;
  onEnter: (value: string) => string;
}

export const InputButton: FC<Props> = props => {
  const [value, setValue] = useState(props.value || '');

  return (
    <FormGroup row>
      <StyledTextField
        size='small'
        autoComplete='off'
        value={value}
        onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
          setValue(e.target.value);
          props.onChange && props.onChange(e.target.value);
        }}
      />
      <StyledButton
        variant='contained'
        disabled={value ? false : true}
        onClick={() => {
          setValue(props.onEnter(value));
        }}
        disableElevation>
        {props.textButton || 'Enter'}
      </StyledButton>
    </FormGroup>
  );
};
const StyledTextField = styled(TextField)`
  & fieldset {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  & .MuiInputBase-input:focus {
    outline: none;
  }
`;

const StyledButton = styled(Button)`
  &.MuiButtonBase-root {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    text-transform: 'lowercase';
  }
`;
