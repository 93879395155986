import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentsState } from '../../types/Payment';

const initialState: PaymentsState = {
  data: [],
  loading: true,
  error: false
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<boolean>) {
      state.error = action.payload;
    },
    setData(state, action: PayloadAction<any>) {
      state.data = action.payload;
    }
  }
});

export default paymentsSlice.reducer;
export const { setLoading, setError, setData } = paymentsSlice.actions;
