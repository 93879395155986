import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import PageFrame from '../components/PageFrame';
import { Tree, TreeNode } from 'react-organizational-chart';
import { User } from '../types/User';
import { ChartItem } from '../components/ChartItem';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import { mobileCheck } from '../utils/mobileCheck';
import { useAppDispatch, useAppSelector } from '../hooks/redux';

export const OrgChart = () => {
  const { userList: users } = useAppSelector(state => state.usersReducer);
  const [topUser, setTopUser] = useState<User | null>(null);

  useEffect(() => {
    setTopUser(users.find((user: User) => user.head_id === user.id) || null);
  }, [users]);

  function getUsers(parent: User): ReactNode {
    const userList = users.filter(user => user.head_id === parent.id);

    const departmentLeadUsers = userList.filter(user => user.department_lead);
    const withoutDepartmentLeadUsers = userList.filter(user => !user.department_lead);
    const teamList = withoutDepartmentLeadUsers.reduce<
      { department: string; teamList: string[] }[]
    >((acc, user) => {
      if (user.department && user.team) {
        const data = acc.find(item => item.department === user.department);

        if (data) {
          if (data.teamList.indexOf(user.team) === -1) {
            acc = acc.map(item => {
              if (item.department === user.department && user.team) {
                return { ...item, teamList: [...item.teamList, user.team] };
              }
              return item;
            });
          }
        } else {
          acc.push({
            department: user.department,
            teamList: [user.team]
          });
        }
      }

      return acc;
    }, []);

    return (
      <>
        {
          // отрисовка department_lead пользователей с плашкой департамента
        }
        {departmentLeadUsers.map(user => {
          const subUsers = users.filter(next => next.head_id === user.id);
          return (
            <TreeNode
              key={`${user.department}_${user.id}`}
              label={<StyledNode className='department'>{user.department}</StyledNode>}>
              {user.team_lead && !subUsers.find(subUser => subUser.team !== user.team) ? (
                <TreeNode label={<StyledNode className='team'>{user.team}</StyledNode>}>
                  <TreeNode label={<ChartItem className='' {...user} />}>
                    {subUsers.length && getUsers(user)}
                  </TreeNode>
                </TreeNode>
              ) : (
                <TreeNode label={<ChartItem className='' {...user} />}>
                  {subUsers.length && getUsers(user)}
                </TreeNode>
              )}
            </TreeNode>
          );
        })}
        {
          // если родитель является team_lead
          // (над ним выставлена плашка с team и её не нужно отрисовывать ещё раз)
        }
        {parent.team_lead && !withoutDepartmentLeadUsers.find(user => user.team !== parent.team)
          ? withoutDepartmentLeadUsers.map(user => {
              const subUsers = users.filter(next => next.head_id === user.id);
              if (subUsers.length) {
                return (
                  <TreeNode
                    key={`${user.team}_${user.id}`}
                    label={<ChartItem className='' {...user} />}>
                    {getUsers(user)}
                  </TreeNode>
                );
              } else {
                return (
                  <TreeNode
                    key={`${user.team}_${user.id}`}
                    label={<ChartItem className='' {...user} />}
                  />
                );
              }
            })
          : teamList.map(item => {
              const department = item.department;
              const teamList = item.teamList;
              let node: ReactNode[] = [];

              teamList.map(team => {
                const teamUsers = withoutDepartmentLeadUsers.filter(user => user.team === team);

                node.push(
                  <TreeNode
                    key={`${department}_${team}`}
                    label={<StyledNode className='team'>{team}</StyledNode>}>
                    {teamUsers.map(user => {
                      const subUsers = users.filter(next => next.head_id === user.id);
                      if (subUsers.length) {
                        return (
                          <TreeNode
                            key={`${user.team}_${user.id}`}
                            label={<ChartItem className='' {...user} />}>
                            {getUsers(user)}
                          </TreeNode>
                        );
                      } else {
                        return (
                          <TreeNode
                            key={`${user.team}_${user.id}`}
                            label={<ChartItem className='' {...user} />}
                          />
                        );
                      }
                    })}
                  </TreeNode>
                );
              });

              if (item.department !== parent.department) {
                return (
                  <TreeNode
                    key={`department_${teamList.join('_')}`}
                    label={<StyledNode className='department'>{item.department}</StyledNode>}>
                    {node}
                  </TreeNode>
                );
              } else {
                return node;
              }
            })}
      </>
    );
  }

  return (
    <PageFrame>
      <h1>Org Chart</h1>

      <TransformWrapper
        initialScale={mobileCheck() ? 0.2 : 0.5}
        minScale={0.1}
        maxScale={1}
        initialPositionX={10}
        initialPositionY={20}
        limitToBounds={false}
        wheel={{ step: 0.05 }}>
        <TransformComponent
          wrapperStyle={{
            width: '100%',
            cursor: 'move',
            background: '#f7f7f7'
          }}>
          {topUser && (
            <Tree
              lineWidth={'2px'}
              lineColor={'#2196f3'}
              lineBorderRadius={'10px'}
              label={<ChartItem className='top' {...topUser} />}>
              {getUsers(topUser)}
            </Tree>
          )}
        </TransformComponent>
      </TransformWrapper>
    </PageFrame>
  );
};

const StyledNode = styled.div`
  padding: 10px;
  font-size: 14px;
  border-radius: 8px;
  display: inline-block;
  & img {
    height: 50px;
  }
  &.department {
    background: #0277bd;
    border: 2px solid #1976d2;
    color: #fff;
  }
  &.team {
    background: #2e7d32;
    border: 2px solid #1976d2;
    color: #fff;
  }
`;
