import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UsersState, User, UserData } from '../../types/User';

const initialState: UsersState = {
  user: null,
  userList: [],
  isLoading: false,
  error: ''
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    fetching: state => {
      state.isLoading = true;
    },
    fetchingError: (state, action: PayloadAction<string>) => {
      state.isLoading = false;
      state.error = action.payload;
      console.error('Error fetching users');
    },
    fetchingSuccess(state, action: PayloadAction<User[]>) {
      state.userList = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    fetchingUser(state, action: PayloadAction<UserData>) {
      state.user = action.payload;
      state.isLoading = false;
      state.error = '';
    },
    resetUser: state => {
      state.user = null;
    },
    updateUser(state, action: PayloadAction<User>) {
      const userData = action.payload;
      state.userList = [...state.userList.filter(user => user.id !== userData.id), userData];
    }
  }
});

export default slice.reducer;
export const Actions = slice.actions;
