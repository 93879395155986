import dayjs from "dayjs";


export enum EarningStatus {
    Draft = 1 ,
    ToBePaid = 2 ,
    Paid = 3,
}

export interface Employee {
    id: number,
    name: string,
    surname: string,
    status: number
}

export interface PaymentsListItem {
    id: number,
    paid_at: string,
    status: EarningStatus,
    employee: Employee,
    amount: number
}

export interface NewEarningFormData {
    date:  dayjs.Dayjs,
    status: EarningStatus,
    employee: Employee['id'] | null,
    amount:  PaymentsListItem['amount']
}

