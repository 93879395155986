import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ImageUpload } from '../components/ImageUpload';
import { timezones } from '../utils/timezones';
import PageFrame from '../components/PageFrame';
import { Paper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { User, UserData } from '../types/User';
import { useAppDispatch, useAppSelector } from '../hooks/redux';
import {
  getProfile,
  getUsers,
  updateProfile,
  deleteProfile
} from '../store/reducers/ActionCreators';

export const Profile = () => {
  const dispatch = useAppDispatch();
  const { user: authUser } = useAppSelector(state => state.authReducer);
  const { user, userList } = useAppSelector(state => state.usersReducer);
  const { countries } = useAppSelector(state => state.managementReducer);
  const [tz, setTz] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [userId, setUserId] = useState<number | null>(authUser?.id || null);
  const [userData, setUserData] = useState<UserData | null>(null);
  const role = authUser?.role;

  function changeUserData(event: React.ChangeEvent<HTMLInputElement>) {
    const name = event?.target?.name;
    const value = event?.target?.value || '';
    if (userData && name) {
      setUserData({ ...userData, [name]: value });
    }
  }

  useEffect(() => {
    if (typeof userId === 'number') {
      dispatch(getProfile(userId));
    }
  }, [userId]);

  useEffect(() => {
    if (user) {
      setUserData({ ...user });
    } else {
      setUserData(null);
    }
  }, [user]);

  useEffect(() => {
    setIsChanged(JSON.stringify(user) !== JSON.stringify(userData));
  }, [userData]);

  function reset() {
    if (user) {
      setUserData({ ...user });
    }
  }

  function save() {
    if (user) {
      const response = dispatch(updateProfile(user));
      response.then(response => {
        if (response.status === 200) {
          dispatch(getUsers());
        }
      });
    }
  }

  function deleteUser() {
    if (user) {
      const response = dispatch(deleteProfile(user.id));
      response.then(response => {
        if (response.status === 200) {
          dispatch(getUsers());
        }
      });
    }
  }

  return (
    <PageFrame>
      <h1>Profile</h1>
      <Paper>
        {role === 'Admin' && (
          <Box p={{ xs: 2, md: 4 }}>
            <Autocomplete
              getOptionLabel={(option: User) => `${option.name} ${option.surname || ''}`.trim()}
              noOptionsText='No users'
              options={userList}
              renderInput={params => <TextField {...params} label='Users' />}
              onChange={(event: React.SyntheticEvent, value: User | null) =>
                setUserId(value?.id || authUser?.id || null)
              }
            />
          </Box>
        )}
        {userData && (
          <Box p={{ xs: 2, md: 4 }}>
            <Grid component='form' container spacing={2}>
              <Grid item xs={12}>
                <Typography variant='h6'>
                  Account Information ({`${userData.name} ${userData.surname || ''}`.trim()})
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <ImageUpload />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='Name'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.name || ''}
                  name='name'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='Surname'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.surname || ''}
                  name='surname'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='Email'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.email || ''}
                  name='email'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='Phone'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.phone || ''}
                  name='phone'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} md={6}>
                {countries && (
                  <Autocomplete
                    getOptionLabel={(country: string) => country}
                    noOptionsText='No result'
                    options={Object.keys(countries).map(key => countries[key])}
                    renderInput={params => <TextField {...params} label='Countries' />}
                    onChange={(event: React.SyntheticEvent, value: string | null) =>
                      setUserData({ ...userData, country: value })
                    }
                  />
                )}
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='City'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.city || ''}
                  name='city'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl fullWidth>
                  <InputLabel id='timezone-select-label'>Timezone</InputLabel>
                  <Select
                    labelId='timezone-select-label'
                    value={userData.timezone || tz}
                    label='Timezone'
                    onChange={(event: SelectChangeEvent<string>) =>
                      setUserData({ ...userData, timezone: event.target.value })
                    }>
                    {Object.entries(timezones).map(([name, value]) => (
                      <MenuItem key={name} value={value}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label='Address'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.address || ''}
                  name='address'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='Emergency contact name'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.emergency_contact_name || ''}
                  name='emergency_contact_name'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='Emergency contact phone'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.emergency_contact_phone || ''}
                  name='emergency_contact_phone'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='Emergency contact description'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.emergency_contact_description || ''}
                  name='emergency_contact_description'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Typography variant='h6'>Bank account</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='SWIFT'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.bank_swift || ''}
                  name='bank_swift'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='Account number (IBAN)'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.bank_iban || ''}
                  name='bank_iban'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='Account first name'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.bank_first_name || ''}
                  name='bank_first_name'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label='Account surname'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.bank_surname || ''}
                  name='bank_surname'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  label='Additional info'
                  variant='outlined'
                  autoComplete='off'
                  value={userData.bank_info || ''}
                  name='bank_info'
                  onChange={changeUserData}
                  fullWidth
                />
              </Grid>

              <Grid item xs={12}>
                <Stack spacing={2} direction='row'>
                  {role === 'Admin' && (
                    <Button variant='contained' onClick={deleteUser}>
                      Delete profile
                    </Button>
                  )}
                  <Button disabled={isChanged ? false : true} variant='outlined' onClick={reset}>
                    Reset
                  </Button>
                  <Button disabled={isChanged ? false : true} variant='contained' onClick={save}>
                    Save changes
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        )}
      </Paper>
    </PageFrame>
  );
};
