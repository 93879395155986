import $api, { API_URL } from './http';
import { AuthResponse } from '../types/AuthResponse';
import { CreatePaymentParams, PaymentData } from '../types/Payment';
import { User, UserData } from '../types/User';
import { Countries, Roles, Statuses, Structure } from '../types/Management';
import { AxiosResponse } from 'axios';

export default class Service {
  static loginWithGoogle() {
    document.location.href = `${API_URL}/auth/google`;
  }

  static async checkUser(): Promise<AxiosResponse> {
    return await $api.get('/api/refresh');
  }

  static logout(): Promise<AxiosResponse> {
    return $api.get('/api/logout');
  }

  static async getPayment(): Promise<any> {
    return await $api.get('/api/payment');
  }

  static async createPayment(data: CreatePaymentParams): Promise<AxiosResponse<PaymentData>> {
    return await $api.post('/api/payment/create', data);
  }

  static async getUsers(): Promise<AxiosResponse<User[]>> {
    return await $api.get('/api/users');
  }

  static async getProfile(id: number): Promise<AxiosResponse<UserData>> {
    return await $api.get(`/api/profile/${id}`);
  }

  static async deleteProfile(id: number): Promise<AxiosResponse> {
    return await $api.delete(`/api/profile/${id}`);
  }

  static async updateProfile(data: Partial<UserData>, image?: File): Promise<AxiosResponse> {
    const userData: any = { ...data };

    if (image) {
      userData.image = image;
    }

    return await $api.post('/api/profile/update', userData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  static async getCountries(): Promise<Countries> {
    const response = await $api.get('/api/countries');
    return response.data;
  }

  static async getStatuses(): Promise<Statuses> {
    const response = await $api.get('/api/statuses');
    return response.data;
  }

  static async getRoles(): Promise<Roles> {
    const response = await $api.get('/api/roles');
    return response.data;
  }

  static async getStructure(): Promise<Structure> {
    const response = await $api.get('/api/get_structure');
    return response.data;
  }

  static async setStructure(data: Structure): Promise<AxiosResponse> {
    return await $api.post('/api/set_structure', data);
  }
}
